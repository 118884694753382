.modal{
    .modal-content{
        padding: 35px;
    }

    .modal-header{
        border: none;
        .modal-title{
            font-family: $FONT_BOLD;
            color: $DARKBLUE;
            font-size: $SIZE_28;
            line-height: $SIZE_25;
            padding-bottom: 20px;
        }
        .btn-close{
            border-radius: 50%;
            margin-top: -50px;
            margin-right: -15px;
            padding: 5px;
            background-color: $LIGHTERGREY;
            width: 10px;
            height: 10px;
            background-position: center;
            background-size: 10px;
        }
    }

    .modal-body {
        font-family: $FONT_FAMILY;
        font-size: $SIZE_18;
        line-height: $SIZE_25;
    }

    &.show{
        .modal-dialog {
            max-width: 350px;
            padding: 19PX 0 47PX;
        }
    }
}