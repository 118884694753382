

body {
    .tmp-container{
        color: $DARKGREY;
        font-family: $FONT_GTREGULAR;
        h1, h2, h3, h4, h5, h6 {
            font-family: $FONT_GTREGULAR !important;
        }
        .font-bold{
            font-family: $FONT_GTBOLD !important;;
        }

        strong{
            font-family: $FONT_GTBOLD !important;;
        }
    }
}
