html,
body {
    overscroll-behavior-y: contain;
}
html {
    font-size: 16px;
}
body {
    margin: 0;
    padding: 0;
    font-family: $FONT_FAMILY;
    line-height: 1.42857143;
    
    p {
        line-height: $SIZE_14;
        font-size: $SIZE_18;
        margin-top: 7.5px;
        margin-bottom: 20px;
    }
    
    a{
        font-weight: normal;
        color: $BLUE;
        text-decoration: none;

        &:hover {
            color: $CERULEAN;
            text-decoration: underline;
            text-decoration-thickness: 1px;
        }
        
        &.callneedhelp {
            text-align: center;
            padding: 4px 24px 48px 24px;
            display: block;
            color: $DARKBLUE;
            font-size: 16px;
            font-weight: 400;
            font-family: $FONT_FAMILY;
        }
    }

    .navbar-brand {
        img {
            height: 35px;
            width: 64px;
        }
    }


    h1, h2, h3, h4, h5, h6 {
        color: $DARKGREY;
        margin: 0 0 10px 0;
        font-family: $FONT_MEDIUM;
        font-size: $SIZE_26;
        margin-top: 12px;
        margin-bottom: 12px;
        clear: both;

    }

    .font-bold{
        font-family: $FONT_BOLD;
    }

    h2, h3, h4, h5, h6{
        margin-top: 7.5px;
        margin-bottom: 7.5px;
    }

    h1{
        font-size: 48px;
        line-height: 56px;
        margin-top: 40px;
        margin-bottom: 12px;
    }

    h2 {
        font-size: 26px;
        margin-top: 20px;
        margin-bottom: 30px;
        line-height: $SIZE_26;
    }

    h3 {
        font-size:  1.75em;
        line-height: 2em;
    }

    h4 {
        font-size: 1.5em;
        line-height: 1.75em;
    }

    h6{
        font-size: 1.125em;
        line-height: 1.5em;
    }

    h2+p{
        margin-top: 0;
        margin-bottom: 20px;
        line-height: 22.4px;
        font-size: $SIZE_16;
        font-family: $FONT_FAMILY;
    }

    .container {
        max-width: 1170px;
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .body-content{
        min-height: 768px;
        padding-bottom: 6.625em;
        ul{
            margin:0.75em 0;
            line-height: 1.5em;
            padding-left: 1em;
            li{
                padding:0;
                margin: 0.75em 0;

                &:first-child{
                    margin-top:0;
                }
                &:last-child{
                    margin-bottom:0;
                }
            }
        }
    }

    .section{
        margin-top: 2.5em;
        margin-bottom: 2.5em;
    }

    input::-ms-clear, input::-ms-reveal {
        display: none;
    }

    .loader{
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        z-index: 999;
        position: fixed;
        -webkit-transition: opacity .5s;
        transition: opacity .5s;
        background-color: $WHITE;
        opacity: 1;
        flex-direction: column;
        justify-content: center;
        &, & .loader-content {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $WHITE;
            font-size:20px;
        }
        .loader-content{
            &>div{
                &>div[class*='css-']{
                    box-shadow: 1px 2px $DARKERCHARCOAL;
                    background-color: $WHITE;
                }
            }
        
        }
        .loader-message{
            display: flex;
            align-items: center;
            justify-content: center;
            color: $WHITE;
            font-size:20px;
            text-shadow: 1px 2px $DARKERCHARCOAL;
        }
    }

    .form-label {    
        margin-bottom: 25px;
        font-size: $SIZE_16;
        line-height: 22.4px;
        font-weight: 700;
    }

    input.form-control, select.form-select{
        min-height: 50px;
        border-radius: 0;
        font-size: $SIZE_18;
        border-color: $GREY;
        padding: 0 20px;
        color: $FORMCONTROL;
    }

    .list .list-group-item {
        font-size: $SIZE_18;
        padding: 15px 0;
        
        border-bottom: 1px solid $MIDGREY;
        &:last-child{
            border-bottom: 1px solid $MIDGREY;
        }

        span{
            text-transform: capitalize;
            &:last-child {
                float: right;
            } 
        }
        &:hover {
            cursor: pointer;
        }
    }

    .table {
        tr{
             td:first-child {
                font-weight: bold;
             }
             td:last-child {
                text-transform: capitalize;
             }
        }
    }
}
