.problem {
    font-family: $FONT_FAMILY;

    &.container {
        width: 360px;
    }
    
    .list div{
        margin-bottom: 10px;
        input {
            width: 20px;
            height: 20px;
        }
        input[type="radio"] {
            margin-top: -1px;
            vertical-align: middle;
            cursor: pointer;
        }
        label {
            font-size: $SIZE_18; 
            text-transform: lowercase;
            line-height: $SIZE_22;
            margin-left: 5px;
            vertical-align: middle;
            height: 22px;

            &:first-letter{
                text-transform: capitalize;
            }
        }
    }
}