.review {
    font-size: $SIZE_18;
    
    .details{
        tbody{
            tr{
                td:first-child{
                    font-style: bold;
                    width: 40%;
                }
                td:last-child {
                    width: 60%;
                }
            }
        }
    }
    .consent {
        margin-top: 10px;
        
        .form-check-input {
            height: 20px;
            width: 20px;
            border-radius: 0px;
        }
        .form-check-label {
            font-size: $SIZE_18;
            line-height: $SIZE_22;
            padding-left: 10px;
        }
    }
    button {
        margin-top: 30px;
    }
    .email {
        .form-label {
            margin-top: 10px;
            margin-bottom: 5px;
        }
    }
}