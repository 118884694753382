.alert-toaster {
    font-size: $SIZE_16;
    line-height: $SIZE_24;
    .alert-toaster-title {
        margin: 0;
        font-size: $SIZE_16;
        line-height: $SIZE_24;
    }


}