.gm-style-cc {
    display: none !important;
}
.pac-container{
    &.pac-logo.hdpi {
        &::after{
           display: none;
        }
    }

    .pac-item{
        border: none;
        padding: 0 1rem;
    }
}

.search-location-input{
    padding: 8px 36px;
}

.no-search-result-wrapper{
    margin-top: -1rem;
}
.no-search-result, .search-result-list{
    background-color: $WHITE;
}

.seach-box-item{
    font-size: $SIZE_14;

    &:active{
        background-color: $LIGHTGREYOPACITY;
    }
}

.icon-maps-pin{
    width: 15px;
    height: 20px;
    display: inline-block;
    background-image: url('./images/pin.svg');
    background-repeat: no-repeat;
    background-position: center center;
}