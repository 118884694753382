$PRIMARY_COLOURS: (
	'blue': #00A5E1,
	'charcoal': #4D4D4D,
	'darkCharcoal': #4A4A4A,
    'darkerCharcoal': #4A4A49,
    'darkestCharcoal': #444,
    'lighterBlue': #A9E5F7, 
    'lightCharcoal': #333333,
);

$SECONDARY_COLOURS: (
	'grey': #A5A5A5,
);

$TERTIARY_COLOURS: (
    'green': #91C84C,
    'malachite': #00cf3f,
	'teal': #00A099,
	'cerulean': #0080AE,
    'purple': #5C2F92,
	'orange': #ff6900,
    'lightOrange': #ff6000,
    'lightGreyOpacity': #FAFAFA,
    'darkgrey': #F0F1F1,
    'lighterGrey': #EBEBEB,
    'darkergrey': #979797,
    'deepgrey': #303030,
    
);

//New Design Colours
$NEW_PRIMARY_COLOURS: ( 
    'darkBlue': #0a00f0,
    'white': #FFFFFF, 
    'brightOrange': #ff6900
);

$NEW_SECONDARY_COLOURS: ( 
    'midBlue': #005aff, 
    'lightBlue': #00b9ff, 
    'lightMidBlue':#0e8ec2,
    'aqua': #7cfefe,
    'red': #ff0037,
    'yellow': #fff000,
    'darkyellow':#ffc700
);

$NEW_GREYSCALE_COLOURS: (
	'black': #000000,
	'darkGrey': #444444,
	'coolGrey': #a4aab3,
	'midGrey': #cccccc,
	'lightGrey': #f5f5f5
);

$NEW_ALL_COLOURS: map-merge(map-merge($NEW_PRIMARY_COLOURS, $NEW_SECONDARY_COLOURS), $NEW_GREYSCALE_COLOURS);

$ALL_COLOURS: map-merge(map-merge(map-merge($PRIMARY_COLOURS, $SECONDARY_COLOURS), $TERTIARY_COLOURS), $NEW_ALL_COLOURS);

// Primary
$BLUE     : map-get($PRIMARY_COLOURS, 'blue');
$CHARCOAL : map-get($PRIMARY_COLOURS, 'charcoal');
$DARKCHARCOAL : map-get($PRIMARY_COLOURS, 'darkCharcoal');
$DARKERCHARCOAL : map-get($PRIMARY_COLOURS, 'darkerCharcoal');
$DARKESTCHARCOAL : map-get($PRIMARY_COLOURS, 'darkestCharcoal');
$LIGHTBLUE : map-get($PRIMARY_COLOURS, 'lightBlue');
$LIGHTERBLUE : map-get($PRIMARY_COLOURS, 'lighterBlue');
$LIGHTCHARCOAL: map-get($PRIMARY_COLOURS, 'lightCharcoal');

// Secondary
$GREY     : map-get($SECONDARY_COLOURS, 'grey');
// Tertiary
$GREEN    : map-get($TERTIARY_COLOURS, 'green');
$MALACHITE: map-get($TERTIARY_COLOURS, 'malachite');
$TEAL     : map-get($TERTIARY_COLOURS, 'teal');
$CERULEAN     : map-get($TERTIARY_COLOURS, 'cerulean');
$PURPLE   : map-get($TERTIARY_COLOURS, 'purple');
$ORANGE   : map-get($TERTIARY_COLOURS, 'orange');
$LIGHTGREY: map-get($TERTIARY_COLOURS, 'lightGrey');
$LIGHTGREYOPACITY: map-get($TERTIARY_COLOURS, 'lightGreyOpacity');
$LIGHTORANGE: map-get($TERTIARY_COLOURS, 'lightOrange');
$MIDGREY : map-get($TERTIARY_COLOURS, 'midgrey');
$OLDDARKGREY : map-get($TERTIARY_COLOURS, 'darkgrey');
$DARKERGREY : map-get($TERTIARY_COLOURS, 'darkergrey');
$DEEPGREY : map-get($TERTIARY_COLOURS, 'deepgrey');
$LIGHTERGREY : map-get($TERTIARY_COLOURS, 'lighterGrey');

//New Primary
$DARKBLUE     : map-get($NEW_PRIMARY_COLOURS, 'darkBlue');
$WHITE     : map-get($NEW_PRIMARY_COLOURS, 'white');
$BRIGHTORANGE     : map-get($NEW_PRIMARY_COLOURS, 'brightOrange');
//New Secondary
$MIDBLUE     : map-get($NEW_SECONDARY_COLOURS, 'midBlue');
$LIGHTMIDBLUE : map-get($NEW_SECONDARY_COLOURS, 'lightMidBlue');
$LIGHTBLUE   : map-get($NEW_SECONDARY_COLOURS, 'lightBlue');
$AQUA     : map-get($NEW_SECONDARY_COLOURS, 'aqua');
$RED     : map-get($NEW_SECONDARY_COLOURS, 'red');
$YELLOW     : map-get($NEW_SECONDARY_COLOURS, 'yellow');
$DARKYELLOW     : map-get($NEW_SECONDARY_COLOURS, 'darkyellow');
//New Greyscale
$BLACK     : map-get($NEW_GREYSCALE_COLOURS, 'black');
$DARKGREY     : map-get($NEW_GREYSCALE_COLOURS, 'darkGrey');
$COOLGREY     : map-get($NEW_GREYSCALE_COLOURS, 'coolGrey');
$MIDGREY     : map-get($NEW_GREYSCALE_COLOURS, 'midGrey');
$LIGHTGREY     : map-get($NEW_GREYSCALE_COLOURS, 'lightGrey');

$PROMO_TRANSPARENCY: 0.9;

//Glance table color
$GLANCEGREEN: #91CB4C;
$GLANCEYELLOW: #F68628;
$CHISTINE: #e26c09; 
$GLANCERED: #EE255C;
$TINTGREY: #d3d3d3;

//All the soft color for background
$ALL_SOFT_COLOURS: ('white','lightGreyOpacity','lightGrey', 'lightCharcoal');

//Angle colour
$ANGLEBLUE: #0F13FF;

$BORDERGREY: #D8D8D8;
$BORDERLIGHTGREY: #ECECEC;

$PAYPALYELLOW: #FFC439;
$PALPALHOVER: #FFAF00;

$FORMCONTROL: #555;
$SUCCESSCONTROL: #3c763d;

$YELLOWBUTTON: #ffe400, #ffc700;
$YELLOWNOTICE: #fff5cc;

$PANELGRADIENT: #777777, #5a5a5a;
$PANELBODY: #f8f8f8;
$PANELBORDER: #dbdbdb;
$PANELSECTION: #dadada;
$ARROWHELP: #ededed;
$COVERMESSAGELINK: #09c;
$BLACKWITHOPACITY: rgba(0, 0, 0, 0.52);
