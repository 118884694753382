body {
  .server-error {
    padding-top: 70px;
    background: rgba(242, 242, 246, 0.5);
  
    h2 {
      font-family: $FONT_GTBOLD;
      font-size: $SIZE_18;
      line-height: $SIZE_24;
      letter-spacing: -0.24px;
      margin-top: 50px;
      font-weight: bold;
    }
  
    .text-box {
      p {
        font-family: $FONT_GTREGULAR;
        font-size: $SIZE_16;
        line-height: $SIZE_24;    
        width: 300px;
        margin: 0 auto;
        margin-top: 15px;
      }
    }
  
    .refresh {
      font-family: $FONT_GTREGULAR;
      font-size: $SIZE_14;
      line-height: 20px;
      color: $DARKBLUE;
      margin-top: 26px;
      display: block;

      img {
        display: inline;
        height: 15px;
        width: 15px;
        vertical-align: text-top;
        margin-right: 5px;
      }
  
      &[aria-disabled="true"] {
        pointer-events: none;
        color: $GREY;
      }
    }
  }
}
