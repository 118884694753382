.validate{
    font-family: $FONT_FAMILY;
    
    .next_btn{
        margin-top: 30px;
        float: right;
        width: 165px;
    }
    .margin_top_20{
        margin-top: 20px;
    }
    .label {
        margin-bottom: 5px;
        font-size: $SIZE_16;
    }
    input {
        margin-top: 20px;
    }

}