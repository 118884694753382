.jobConfirmation{
    font-size: $SIZE_18;
    
    .head-section{
        margin-bottom: 30px;
        p {
            font-size: $SIZE_18;
            font-weight: 700;
            line-height: 25px;
            &:first-child {
                margin-bottom: 0px !important;
            }
            &.sub-header {
                font-weight: bold;
            }
        }
    }
    .details{
        tr td{
            line-height: 28px;
        }
        &:first-child {
            margin-top: 30px;
        }
        tr td:first-child {
            width: 200px;
        }
    }
}