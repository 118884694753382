.confirm-vehicle {
    h2+p {
        margin-bottom: 0;
    }
    form {
        select {
                text-transform: uppercase;
        }
    }
    button {
        margin-top: 30px;
    }
}