.begin {
    font-family: $FONT_FAMILY;
    .options {
        p{
            line-height: 10px;
            margin-top: 20px;

            &:last-child {
                margin-top: 50px;
            }
        }
        p a {
            font-size: $SIZE_18;
            line-height: 21px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}