.btn {
    width: 165px;
    height: 50px;

    &.btn-gray {
        padding: 8px 10px;
        min-width: 110px;
        background-color: $LIGHTERGREY;

        &:active{
            background-color: $BLUE;
            color: $WHITE;
        }
    }
    
    &.btn-orange {
        padding: 5px 10px;
        min-width: 120px;
        margin-bottom: 30px;
        font-size: $SIZE_24;
        background-color: $GLANCEYELLOW;
        font-family: $FONT_BOLD;
        color: $WHITE;
        border: none;

        &:active, &:hover, &:focus{
            background-color: $CHISTINE;
            color: $WHITE;
        }
    }

    &.btn-blue {
        background: $BLUE;
        color: $WHITE;

        &:active, &:hover, &:focus{
            background-color: $BLUE;
            color: $WHITE;
        }
    }

    &.btn-rouded{
        border-radius: 100px;
    }
}