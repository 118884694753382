body {
  .map-failure {
    background-color: rgba(242, 242, 246, 0.5);
  
    .row {
        margin-top: 145px;
    }
  
    h2 {
      font-family: $FONT_GTBOLD;
      font-size: $SIZE_18;
      line-height: $SIZE_24;
      letter-spacing: -0.24px;
    }
  }
}