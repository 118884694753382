.cancel.link {
  float: right;
  color: $DARKBLUE;
  font-size: $SIZE_14;
  line-height: 20px;

  &[aria-disabled="true"] {
    pointer-events: none;
    color: $GREY;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    vertical-align: text-top;
  }
}

body .tmp-container {
  .cancel.page {
    .form {
      padding: 15px;
    }

    h2,
    .description {
      text-align: center;
      line-height: 24px;
    }

    h2 {
      font-family: $FONT_GTBOLD !important;
      font-size: $SIZE_18;
      letter-spacing: -0.24px;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    .description,
    .survey {
      font-family: $FONT_GTREGULAR;
      font-size: $SIZE_16;
      color: $DARKESTCHARCOAL;
    }

    .description {
      width: 315px;
      margin: 0 auto;
      margin-bottom: 27px;
    }

    .survey {
      margin-bottom: 10px;

      &.header {
        margin-bottom: 20px;
      }

      input {
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }

      label {
        margin-left: 12px;
        vertical-align: middle;
      }
    }

    .btn {
      width: 100%;
      margin-top: 20px;
      border-radius: 100px;
      font-family: $FONT_GTREGULAR;
      font-size: $SIZE_18;
      line-height: 24px;

      &.btn-blue {
        background-color: $DARKBLUE;
        color: $WHITE;
      }

      &.btn-white {
        background-color: $WHITE;
        color: $DARKBLUE;
        border: solid 2px $DARKBLUE;
      }
    }
  }
}