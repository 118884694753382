$fonts: NRMAWeb-Bold
		NRMAWeb-Medium
		NRMAWeb-Regular
		GTEestiDisplay-Bold
    GTEestiDisplay-Regular
		;

@each $font in $fonts {
	@font-face {
		font-family: $font;
		src: url('../fonts/#{$font}.woff') format('woff'),
		url('../fonts/#{$font}.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
	}
}
