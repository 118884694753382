.error {
  float: right;
  color: $DARKBLUE;
  font-size: $SIZE_14;
  line-height: 20px;

  &[aria-disabled="true"] {
    pointer-events: none;
    color: $GREY;
  }

  &.modal {
    .modal-dialog {
      margin-top: 72.5px;
      margin-left: 15px;
      margin-right: 15px;

      @media (min-width: 576px) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .modal-content {
      padding: 0;
    }

    .modal-header {
      display: block;
      position: relative;
      text-align: right;
      padding: 15px 0 0;
      margin-right: 15px;

      .btn-close {
        width: 20px;
        height: 20px;
        background-color: transparent;
        background-size: 20px;
      }
    }

    &.show {
      .modal-dialog.modal-fullscreen-sm-full {
        max-width: none;
        padding: 0;
      }
    }

    .modal-body {
      padding: 15px;
      padding-top: 0;

      h2,
      .description,
      .request-active,
      .call-us {
        text-align: center;
        line-height: 24px;
      }

      h2 {
        font-family: $FONT_GTBOLD;
        font-size: $SIZE_18;
        letter-spacing: -0.24px;
        margin-top: 15px;
        margin-bottom: 0;
      }

      .description,
      .request-active,
      .call-us {
        font-family: $FONT_GTREGULAR;
        font-size: $SIZE_16;
        line-height: 24px;
        color: $DARKESTCHARCOAL;
      }

      .description {
        margin-bottom: 25px;
      }

      .request-active {
        margin-bottom: 25px;
      }

      .call-us {
        margin-bottom: 8px;

        a {
          color: $DARKBLUE;
        }
      }

      .btn {
        width: 100%;
        margin-top: 20px;
        border-radius: 100px;
        font-family: $FONT_GTREGULAR;
        font-size: $SIZE_18;
        line-height: 24px;

        &.btn-blue {
          background-color: $DARKBLUE;
          color: $WHITE;
        }

        &.btn-white {
          background-color: $WHITE;
          color: $DARKBLUE;
          border: solid 2px $DARKBLUE;
        }
      }
    }
  }

  img {
    width: 20px;
    height: 20px;
    
    &.error-icon {
      width: 36px;
      height: 36px;
      margin: 0 auto;
      display: block;
    }
  }
}