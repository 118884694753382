@import 'no-active-job';
@import 'map-failure';
@import 'tmp-font-override';
@import 'cancel';
@import 'error';

.tmp-container{
    .tmp-header{
        border-bottom: 1px solid $MIDGREY;
        display: flex;
        justify-content: center;
        align-items: center;    
        padding-top: 7.5px;
        padding-bottom: 7.5px;
        .tmp-header-text{
            font-size: $SIZE_18;
            line-height: $SIZE_24;
            font-family: $FONT_GTBOLD;
        }
    }

    .tmp-job-details{
        font-size: $SIZE_16;
        line-height: $SIZE_24;
        position: relative;
        .stepper-wrapper{
            width: 120px;
            margin-left: auto;
            margin-right: auto;
        }
        
        .circle{
            position: absolute;
            text-align: center;
            top: 0;
            height: 141px;
            width: 141px;
            border-radius: 50%;
            box-shadow: 0 0 10px rgba(0,0,0,0.2);
            background-color: white;
            right: 50%;
            transform: translate(50%, -45%);
            padding-top: 2.25rem;

            &:before{
                position: absolute;
                content: "";
                width: 150%;
                height: 70%;
                left: -25%;
                top: 45%;
                background: $WHITE;
            }
        }

        .eta-wrapper{
            min-height: 5em;
            margin-top: -2.5em;
            .eta{
                font-size: $SIZE_32;
                line-height: $SIZE_42;
            }
            .eta-unit{
                font-size: $SIZE_20;
                line-height: $SIZE_28;
            }
            
        }

        .job-status{
            font-size: $SIZE_16;
            line-height: $SIZE_24;
        }

        .estimate-arrival{
            font-size: $SIZE_14;
            line-height: $SIZE_20;
        }
        .last-updated {
            font-size: $SIZE_12;
            line-height: $SIZE_16;
        }
        .location-header{

            &.disabled{
                color: $GREY;
            }
            color: $DARKBLUE;
            font-size: $SIZE_14;
            line-height: $SIZE_20;  
        }
        .job-location-icon{
            height: 15px;
            width: 15px;
            margin-top: 2px;
        }
    }
    
    .change-location {
        color: $DARKBLUE;
        font-size: $SIZE_14;
        line-height: $SIZE_20;
        text-decoration: none;
    }

    .gm-style > div:not(:last-child) > div{
        box-shadow: inset 0 -10px 10px -10px rgba(0,0,0,0.2);
        
    }
}