.margin_top_20{
    margin-top: 20px;
}

.align_right{
    float: right;
}

.error{
    color: red;
    font-size: $SIZE_18;

    &.show {
        border: 2px solid #EE255C;
        background-color: rgba(238,37,92,0.1);
        color: #4D4D4D;
        -webkit-transition: display ease-in 3s;
        transition: display ease-in 3s;
        display: block;
        padding: 15px;
        font-size: 18px;

        &:before {
            content: "";
            border-width: 0 5px 5px;
            border-style: solid;
            border-color: transparent transparent #EE255C;
            display: block;
            width: 5px;
            height: 5px;
            margin-left: 15px;
        }
    }
}

.rsa-modal {
    top: 200px !important;
}

.font-18 {
    font-size: $SIZE_18;
}