@charset "UTF-8";

$FONT_SIZE       : 18px;
$MOBILE_FONT_SIZE: 20.25px;
$FALLBACK_FONT   : Calibri, Arial, sans-serif;
$FONT_FAMILY     : NRMAWeb-Regular, GTEestiDisplay-Regular, $FALLBACK_FONT;
$FONT_MEDIUM     : NRMAWeb-Medium, GTEestiDisplay-Regular, $FALLBACK_FONT;
$FONT_BOLD       : NRMAWeb-Bold, GTEestiDisplay-Bold, $FALLBACK_FONT;
$FONT_HEAVY      : NRMAWeb-Regular, GTEestiDisplay-Bold, $FALLBACK_FONT;
$FONT_GTREGULAR     : GTEestiDisplay-Regular, $FALLBACK_FONT;
$FONT_GTBOLD        : GTEestiDisplay-Bold, $FALLBACK_FONT;

$FONT_SANS       : 'Open Sans',sans-serif;
$FONT_WEIGHT     : 400;
$FONT_STYLE      : normal;

$SIZE_12         : 12px;
$SIZE_14         : 14px;
$SIZE_16         : 16px;
$SIZE_18         : 18px;
$SIZE_20         : 20px;
$SIZE_22         : 22px;
$SIZE_24         : 24px;
$SIZE_25         : 25px;
$SIZE_26         : 26px;
$SIZE_28         : 28px;
$SIZE_32         : 32px;
$SIZE_42         : 42px;
